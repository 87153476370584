.addLodgingInfoArea {
	background-color: var(--primaryBackgroundColorLight);
	border-bottom: thick solid var(--accentColorLight);
	padding-left: 15px;
	padding-right: 15px;
	padding-bottom: 25px;
	position: relative;
	top: -30px;
	margin-bottom: -15px;
	z-index: 1;
	border-radius: 10px;
	max-height: 0;
	overflow: hidden;
}

.hiding {
	padding-bottom: 15px;
	transition: max-height 0.6s ease-in-out, padding-top 0.4s 0.25s,
		border 0.1s ease-in-out, background-color 0.3s ease,
		padding-bottom 0.8s ease;
}

.visible {
	max-height: 800px;
	padding-top: 15px;
	transition: max-height 0.6s ease-in-out, padding-top 0.4s,
		border 0.1s ease-in-out, background-color 0.3s ease;
	background-color: var(--primaryBackgroundColorLight);
}

.addLodgingArea {
	padding-top: 5px;
}

.answerHolder {
	margin-bottom: 0px;
}

.addingLodgingInfoTitle {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 5px;
	padding-bottom: 5px;
	width: 100%;
}

.addingLodgingInfoTitle > div {
	display: flex;
	cursor: pointer;
}

.addingLodgingInfoTitle > div:first-of-type {
	width: 60%;
}

.lodgingInfoTitle {
	max-width: 50%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.lodgingItemName {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.scheduledTripsItemDestination {
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.lodgingInfoContent > div {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	gap: 10px;
}

.lodgingInfoContent > div > div {
	position: relative;
	padding: 15px;

	border-radius: 20px;
	background-color: var(--secondaryBackgroundColorLight);
	width: fit-content;
}

.lodgingInfoContentLabel {
	font-weight: bold;
	font-size: small;
	display: flex;
	gap: 5px;
	margin-bottom: 10px;
	cursor: default;
}

.lodgingInfoContentLabel > div:first-of-type {
	color: var(--accentColorLight);
}

/* .addLodgingInfoArea .locationSearchResultItem, */
/* .addLodgingArea .answerOption, */
.tripLodgingAreaItemMenu .scheduledTripsItem,
.tripLodgingAreaItemMenu .mainBtn {
	border-color: var(--secondaryBackgroundColorLight);
	background-color: var(--secondaryBackgroundColorLight);
}

.addLodgingInfoArea .locationSearchResultItem:hover {
	background-color: var(--lowkeyBtnColorLight);
}

.addLodgingInfoArea .locationSearchResultItem.selectedItem {
	border-color: var(--accentColorLight);
}

.addLodgingArea .answerOption.selectedOption {
	border-color: var(--accentColorLight);
}

/* .addLodgingArea .answerOption:hover, */
.tripLodgingAreaItemMenu .mainBtn:hover,
.tripLodgingAreaItemMenu .scheduledTripsItem:hover {
	border-color: var(--lowkeyBtnColorLight);
	background-color: var(--lowkeyBtnColorLight);
}

.addLodgingArea .answerOption.selectedOption:hover {
	border-color: var(--accentColorLight);
}

/* .addLodgingInfoArea .mainTxtInput,
.addLodgingArea .questionnaireSingleLine,
.addLodgingArea textarea {
	background-color: var(--secondaryBackgroundColorLight);
	border-color: var(--secondaryBackgroundColorLight);
} */

.itemContentMovingDetails th {
	margin-right: 10px;
	margin-bottom: 5px;
	font-size: small;
	display: flex;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
	cursor: default;
}

.itemContentMovingDetails th > div:first-of-type {
	color: var(--accentColorLight);
	margin-right: 5px;
}

.fileInput {
	border-style: solid;
	padding: 20px;
	border-radius: 20px;
	border-color: var(--accentColorLight);
	width: 87%;
}

.transportationLodgingTypeSelection {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.transportationLodgingTypeSelection > div:first-of-type {
	color: var(--accentColorLight);
}

.addLodgingArea .newActivitySection > div:last-of-type {
	/* background-color: aqua; */
	/* border-color: firebrick; */
	padding: 0.5em;
}

.newItineraryItem {
	background-color: var(--secondaryBackgroundColorLight);
	border-radius: 10px;
	padding: 10px;
}

.newItineraryItemTitle {
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
}

.newItineraryItemForm {
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 1em;
	padding-bottom: 1em;
	/* background-color: red; */
}

.ItineraryDayContent .mainSubmitBtn {
	width: 90%;
}

.ItineraryDayContent .mainTxtInput {
	text-align: left;
	font-size: normal;
	font-weight: normal;
	margin-bottom: 20px;
}

.newItineraryItemForm > input {
	text-align: center;
	font-size: large;
	background-color: var(--primaryBackgroundColorLight);
	color: var(--primaryTxtColorLight);
	padding: 11px;
	border-style: solid;
	border-color: var(--primaryBackgroundColorLight);
	border-radius: 10px;
	z-index: 1000;
	width: 7em;
	height: 60px;
}

.activitySelectionContainer,
.activityContainer {
	display: flex;
	flex-wrap: wrap;
	max-height: 260px;
	overflow-y: auto;
	scrollbar-color: #007 #bada55;
	align-items: flex-start;
}

.activitySelectionOption {
	display: flex;
	align-items: center;
	gap: 3px;

	background-color: var(--lowkeyBtnColorLight);
	border-color: var(--lowkeyBtnColorLight);
	border-style: solid;
	border-width: medium;
	padding: 10px;
	border-radius: 10px;
	margin: 4px;
	height: 20px;
	cursor: pointer;
}

.activitySelectionOption > div {
	display: flex;
	justify-content: center;
}

.activitySelectionOption:hover {
	background-color: var(--lowkeyBtnColorHoverLight);
	border-color: var(--lowkeyBtnColorHoverLight);
}

.selectedActivityOption,
.selectedActivityOption:hover {
	font-weight: bold;
	border-style: solid;
	border-width: medium;
	border-color: var(--accentColorLight);
}

.newActivitySection > div:first-of-type {
	/* Section Header */
	cursor: pointer;
	border-radius: 10px;
	height: 2em;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	background-color: var(--secondaryBackgroundColorLight);
	position: relative;
	z-index: 1000;
	border-bottom: 0px solid var(--accentColorLight);
	transition: max-height 0.35s ease-in-out, border-color 0.4s ease 0.2s,
		border-width 0.3s ease 0.2s;
}

.newActivitySection > div:last-of-type {
	background-color: var(--secondaryBackgroundColorLight);
	padding-top: 1em;
	margin-bottom: -0.5em;
	position: relative;
	top: -1em;
	z-index: 0;
	border-radius: 10px;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.35s ease-in-out, border-color 0.4s ease 0.2s,
		border-width 0.3s ease 0.2s;
	/* transition: border-width 0.3s ease 0.1s, max-height 0.5s ease-in-out,
		border-color 0.4s ease 0s; */
}

.newActivitySection.sectionSelected > div:last-of-type {
	max-height: 600px;
	/* transition: max-height 0.5s ease-in-out, border-color 0.4s ease 0.2s,
		border-width 0.3s ease 0.2s; */
	transition: border-width 0.3s ease 0.1s, max-height 0.5s ease-in-out,
		border-color 0.4s ease 0s;
}

.newActivitySection > div:first-of-type > div {
	display: flex;
	align-items: center;
	gap: 5px;
}

.newActivitySection > div:first-of-type > div:first-of-type > div {
	display: flex;
	align-items: center;
}

.newActivitySection > div:first-of-type > div:last-of-type {
	overflow: hidden;
	white-space: nowrap;
	max-width: 60%;
	text-overflow: ellipsis;
}

/* This is the subsection icon when section selected */
.newActivitySection.sectionSelected
	> div:first-of-type
	> div
	> div:first-of-type {
	color: var(--accentColorLight);
}

.newActivitySection.sectionSelected > div:first-of-type {
	border-bottom: medium solid var(--accentColorLight);
	background-color: var(--secondaryBackgroundColorLight);
}

.NewActivityTitle {
	margin-top: 5px;
	margin-bottom: 10px;
	display: flex;
	z-index: 1;
}

.mainSubmitBtn.SaveItemBtn {
	width: 95%;
	text-align: center;
	margin-top: 0;
}

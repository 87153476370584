.dateSelector {
	padding: 10px;
	background-color: var(--primaryBackgroundColorLight);
	color: var(--primaryTxtColorLight);
	border-radius: 10px;
	margin: 10px;
	width: 100px;
	height: 100px;
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	transition: box-shadow 150ms ease-in-out;
}

/* .addLodgingInfoArea .dateSelector {
	background-color: var(--secondaryBackgroundColorLight);
} */

.dateSelectorFocused {
	box-shadow: 0px 0px 10px var(--accentColorLight);
}

.dateSelectorType {
	font-size: small;
}

.dateSelectorType {
	font-size: small;
}

.dateSelectorDay,
.dateSelectorMonth {
	font-size: x-large;
}

.dateSelectorDay,
.dateSelectorMonth {
	margin-top: 2px;
	margin-bottom: 2px;
}

.dateSelectorDay,
.dateSelectorMonth,
.dateSelectorYear {
	display: inline-block;
	cursor: pointer;
	border-radius: 10px;
	padding: 5px;
}

.dateSelectorDay:hover,
.dateSelectorMonth:hover,
.dateSelectorYear:hover,
.dateSelectorHover {
	background-color: var(--secondaryBackgroundColorLight);
	font-weight: bold;
}

/* .addLodgingInfoArea .dateSelectorDay:hover,
.addLodgingInfoArea .dateSelectorMonth:hover,
.addLodgingInfoArea .dateSelectorYear:hover,
.addLodgingInfoArea .dateSelectorHover {
	background-color: var(--lowkeyBtnColorLight);
} */

.dateSelectorDivider {
	margin: 0 5px;
	display: inline-block;
}

.fromAndToDateSelector {
	display: flex;
	justify-content: center;
	align-items: center;
}

.invisibleDateInput {
	position: absolute;
	height: 100px;
	opacity: 0;
	margin: 10px;
	cursor: pointer;
}

.travelDates {
	font-size: small;
	/* margin-top: 5px; */
}

.adventureMainPage .travelDates {
	margin-top: 10px;
}

.travelDates:hover {
	text-decoration: underline;
	cursor: pointer;
}

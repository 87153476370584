@font-face {
	font-family: logoFont;
	src: url(../public/SupplyCenter-0W9nz.ttf);
}

body {
	font-family: Roboto;
	overflow-x: hidden;
	height: 100%;
}

.App {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	border-radius: 10px;
	margin-bottom: 30px;
}

.App-body {
	flex-direction: column;
	align-items: center;
	font-size: calc(10px + 2vmin);
	transition: 'height 0.3s ease';
	box-shadow: 0px 0px 20px 5px var(--lowkeyBtnColorLight);
	background-color: var(--secondaryBackgroundColorLight);
	border-style: solid;
	border-color: var(--secondaryBackgroundColorLight);
	border-width: thin;
	padding: 15px 15px 15px 15px;
	border-radius: 10px;

	border-top-style: solid;
	border-top-width: thick;
	border-top-color: var(--accentColorLight);
	border-bottom-style: solid;
	border-bottom-width: thick;
	border-bottom-color: var(--accentColorLight);

	font-family: Roboto;

	color: var(--primaryTxtColorLight);
}

.App-footer {
	font-family: Arial, Helvetica, sans-serif;
}

.App-body a[href='#'] {
	color: var(--primaryTxtColorLight);
}

.App-body a[href='#']:hover {
	text-decoration: underline;
}

.gallivibe-company {
	line-height: 25px;
	font-size: small;
	font-weight: normal;
	color: var(--primaryTxtColorLight);
}

.gallivibe-company a {
	color: var(--primaryTxtColorLight);
	text-decoration: none;
}

h1 {
	font-family: logoFont;
	color: var(--primaryTxtColorLight);
	font-size: x-large;
}

input:focus {
	outline-color: var(--accentColorLight);
}

:root {
  --primaryBackgroundColorLight: #ffffff;
  --secondaryBackgroundColorLight: #f3f3f3;
  --accentColorLight: #4aa96e;
  --primaryTxtColorLight: #333333;
  --secondaryTxtColorLight: #666666;
  --HighlightBackgroundLight: #75d2a2;
  --lowkeyBtnColorLight: #e0e0e0;
  --lowkeyBtnColorHoverLight: #d0d0d0;
  /* Emoji Picker */
  --rgb-accent: 74, 169, 110;
  --rgb-background: 255, 255, 255;
  --rgb-input: 243, 243, 243;
  --rgb-color: 51, 51, 51;

  /* --primaryBackgroundColorLight: #1e1e1e;
  --secondaryBackgroundColorLight: #2a2a2a;
  --accentColorLight: #92da4a;
  --primaryTxtColorLight: #ededed;
  --secondaryTxtColorLight: #b0b0b0;
  --HighlightBackgroundLight: #beef75;
  --lowkeyBtnColorLight: #3a3a3a;
  --lowkeyBtnColorHoverLight: #4a4a4a; */

  --primaryBackgroundColorDark: #1e1e1e;
  --secondaryBackgroundColorDark: #2a2a2a;
  --accentColorDark: #92da4a;
  --primaryTxtColorDark: #ededed;
  --secondaryTxtColorDark: #b0b0b0;
  --HighlightBackgroundDark: #beef75;
  --lowkeyBtnColorDark: #3a3a3a;
  --lowkeyBtnColorHoverDark: #4a4a4a;
  /* Emoji Picker */
  /* --rgb-accent: 146, 218, 74;
  --rgb-background: 30, 30, 30;
  --rgb-input: 42, 42, 42;
  --rgb-color: 237, 237, 237; */
}

/* Override variables for dark theme if system preference is dark */
@media (prefers-color-scheme: dark) {
  :root {
    --primaryBackgroundColorDark: #1e1e1e;
    --secondaryBackgroundColorDark: #2a2a2a;
    --accentColorDark: #92da4a;
    --primaryTxtColorDark: #ededed;
    --secondaryTxtColorDark: #b0b0b0;
    --HighlightBackgroundDark: #beef75;
    --lowkeyBtnColorDark: #3a3a3a;
    --lowkeyBtnColorHoverDark: #4a4a4a;
    --rgb-accent: 146, 218, 74;
    --rgb-background: 30, 30, 30;
    --rgb-input: 42, 42, 42;
    --rgb-color: 237, 237, 237;
  }
}

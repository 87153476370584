.emojiBtn {
	flex: 0 0 15%;
	margin-right: 10px;
	font-size: x-large;
	cursor: pointer;
	border-style: solid;
	background-color: var(--lowkeyBtnColorLight);
	border-color: var(--lowkeyBtnColorLight);
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.emojiBtn:hover {
	background-color: var(--lowkeyBtnColorHoverLight);
	border-color: var(--lowkeyBtnColorHoverLight);
}

.questionHolder {
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
}

.question {
	margin-bottom: 10px;
	color: var(--primaryTxtColorLight);
}

.answerHolder {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.answerOption {
	background-color: var(--lowkeyBtnColorLight);
	border-color: var(--lowkeyBtnColorLight);
	color: var(--primaryTxtColorLight);
	cursor: pointer;
	border-radius: 10px;
	border-style: solid;
	border-width: medium;
	text-align: center;
	justify-content: center;
	padding: 5px;
	padding-left: 8px;
	padding-right: 8px;
	display: inline-block;
	flex: 1;
	flex-basis: 40%;
	max-width: 43%;
	margin: 5px;
	transition: border-color 0s;
}

.answerOption:hover {
	/* font-weight: bold; */
	border-color: var(--lowkeyBtnColorHoverLight);
	background-color: var(--lowkeyBtnColorHoverLight);
}

.questionnaireSingleLine,
.questionnaireTextarea {
	margin-bottom: 5px;
	font-size: medium;
	display: inline-block;
	min-height: 1em;
	background-color: var(--lowkeyBtnColorLight);
	border-color: var(--lowkeyBtnColorLight);
	color: var(--primaryTxtColorLight);
	border-collapse: collapse;
	border-style: solid;
	border-radius: 10px;
	padding: 10px;
	text-align: left;
	flex-basis: calc(100% - 30px);
	transition: box-shadow 150ms ease-in-out;
}

.questionnaireTextarea {
	width: calc(100% - 6px);
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	box-sizing: border-box;
	resize: none;
}

.questionnaireSingleLine:focus,
.questionnaireTextarea:focus {
	box-shadow: 0px 0px 10px var(--accentColorLight);
	border-collapse: collapse;
	outline: none;
}

.selectedOption:hover {
	border-color: var(--accentColorLight);
	font-weight: bolder;
	border-style: solid;
	border-width: medium;
	transition: border-color 0s;
	/* background-color: lime; */
}

.selectedOption {
	border-color: var(--accentColorLight);
	font-weight: bold;
	border-style: solid;
	border-width: medium;
	transition: border-color 0s;
	/* background-color: lime; */
}

html {
  background-color: var(--primaryBackgroundColorLight);
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
  height: 100%;
}

div,
span {
  transition: border-color 0.5s, background-color 0.3s, color 0.1s;
}

::selection {
  background-color: var(--accentColorLight);
  color: var(--primaryTxtColorLight);
}

/* For Firefox, you also need */
::-moz-selection {
  background-color: var(--accentColorLight);
  color: var(--primaryTxtColorLight);
}

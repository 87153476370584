.mainSubmitBtn {
	width: 100%;
	padding: 10px;
	border-radius: 10px;
	border-style: solid;
	border-width: 0px;
	font-weight: bold;
	font-size: medium;
	margin-top: 20px;
	cursor: pointer;
	border-color: var(--accentColorLight);
	background: var(--accentColorLight);
	color: var(--secondaryBackgroundColorLight);
}

.mainSubmitBtn:hover {
	cursor: pointer;
	background-color: var(--HighlightBackgroundLight);
	border-color: var(--HighlightBackgroundLight);
}

.mainBtn {
	font-size: medium;
	display: flex;
	justify-content: center; /* Horizontally center */
	align-items: center; /* Vertically center */
	margin-bottom: 0px;
	margin-top: 0;
	min-height: 25px;
	background-color: var(--lowkeyBtnColorLight);
	color: var(--primaryTxtColorLight);
	width: 95%;
	border-radius: 10px;
	padding: 10px;
	text-align: center;
}

.mainBtn:hover {
	cursor: pointer;
	/* font-weight: bold; */
	background-color: var(--lowkeyBtnColorHoverLight);
	color: var(--primaryTxtColorLight);
	/* box-shadow: 0px 0px 7px lightgray; */
}

.mainTxtInput {
	padding: calc(7px + 1vmin);
	width: calc(95% - 7px - 1vmin);
	border-radius: 10px;
	border-style: solid;
	background-color: var(--primaryBackgroundColorLight);
	border-color: var(--primaryBackgroundColorLight);
	color: var(--primaryTxtColorLight);
	font-weight: bold;
	font-size: large;
	outline: none;
	text-align: center;
	display: flex;
	justify-content: flex-start;
	transition: box-shadow 150ms ease-in-out;
}

.mainTxtInput:focus,
.mainTxtInput:active {
	box-shadow: 0px 0px 10px var(--accentColorLight);
}

input[type='time'] {
	transition: box-shadow 150ms ease-in-out;
	cursor: pointer;
}

input[type='time']:focus {
	box-shadow: 0px 0px 10px var(--accentColorLight);
}

.mainBoxBtn {
	margin-bottom: 5px;
	font-size: medium;
	display: inline-block;
	min-height: 1em;
	background-color: var(--lowkeyBtnColorLight);
	width: 95%;
	border-radius: 10px;
	padding: 10px;
	padding-top: 15px;
	padding-bottom: 15px;
	text-align: center;
}

.mainBoxBtn:hover {
	cursor: pointer;
	/* font-weight: bold; */
	background-color: var(--lowkeyBtnColorHoverLight);
	box-shadow: 0px 0px 7px var(--lowkeyBtnColorLight);
}

.mainBtn.wHighlight,
.mainBoxBtn.wHighlight {
	margin-bottom: 7px;
	position: relative;
	left: -6px;
}

.wHighlight {
	border-left: thick solid var(--accentColorLight);
	border-right: thick solid var(--accentColorLight);
}

.wHighlight:hover {
	border-left: thick solid var(--HighlightBackgroundLight);
	border-right: thick solid var(--HighlightBackgroundLight);
}

.mainBtn.wHighlight:hover,
.mainBoxBtn.wHighlight:hover {
	border-left: thick solid var(--HighlightBackgroundLight);
	border-right: thick solid var(--HighlightBackgroundLight);
}

.backLink {
	color: var(--primaryTxtColorLight);
	cursor: pointer;
}

.backLink:hover {
	text-decoration: underline;
}

.locationSearchResultItem {
	width: calc(100% - 20px);
	background-color: var(--lowkeyBtnColorLight);
	border-color: var(--lowkeyBtnColorLight);
	border-radius: 10px;
	flex-basis: 1;
	margin-bottom: 5px;
	padding: 10px;
	cursor: pointer;
	border-style: solid;
	border-width: thin;
}

.locationSearchResultItem:hover {
	background-color: var(--lowkeyBtnColorHoverLight);
}

.selectedItem {
	border-style: solid;
	border-color: var(--accentColorLight);
	border-width: 2px;
	font-weight: 450;
}

@keyframes shake {
	0% {
		transform: translateX(0);
	}
	10%,
	30%,
	50%,
	70%,
	90% {
		transform: translateX(-5px);
	}
	20%,
	40%,
	60%,
	80% {
		transform: translateX(5px);
	}
	100% {
		transform: translateX(0);
	}
}

.shake {
	animation: shake 0.5s;
}

.backArrowBtn {
	cursor: pointer;
}

.mainPageHeader {
	display: flex;
	justify-content: space-between;
}
.mainPageHeader > div {
	flex: 6;
}

.mainPageHeader > div:first-of-type,
.mainPageHeader > div:last-of-type {
	flex: 0.4;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.dateTimeComponentDate .dateSelector {
	margin: 0px;
}

.dateTimeComponent {
	display: flex;
	margin: 0 auto;
	align-items: flex-start;
}

.dateTimeComponentLeft {
	display: flex;
	flex-direction: column;
	flex: 3;
}

.dateTimeComponentRight {
	display: flex;
	flex-direction: column;
	flex: 7;
	/* background-color: turquoise; */
}

.dateTimeComponentTime {
	/* height: 50px; */
	padding-top: 10px;
	padding-bottom: 10px;
	/* background-color: tomato; */
	align-content: center;
	text-align: center;
	justify-items: center;
}

.dateTimeComponentTime input {
	background-color: var(--primaryBackgroundColorLight);
	border-collapse: collapse;
	border-style: solid;
	border-color: var(--primaryBackgroundColorLight);
	border-radius: 20px;
	padding: 10px;
	width: 100px;
	font-family: Roboto;
	font-size: 15px;
	text-align: center;
	color: var(--primaryTxtColorLight);
}

.dateTimeComponentDate {
	padding: 0px;
	text-align: center;
	height: 120px;
}

.dateTimeComponentInvisibleRow {
	flex: 1 1 auto;
}

.dateTimeComponentAddress {
	text-align: center;
	padding-left: 5px;
	margin-right: 7px;
}

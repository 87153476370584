.scheduledTripsItem {
	min-height: 70px;
	/* background-color: var(--secondaryBackgroundColorLight); */
	background-color: var(--primaryBackgroundColorLight);
	border-radius: 10px;
	margin-bottom: 10px;
	padding: 13px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
	transition: box-shadow 0.3s ease, font-weight 0.2s ease;
}

.scheduledTripsItem > div {
	display: flexbox;
	align-items: center;
}

.scheduledTripsItemTimeLeft {
	align-items: flex-start;
}

.adventureLocations .scheduledTripsItem {
	margin-bottom: 2px;
	background-color: var(--secondaryBackgroundColorLight);
	height: 100px;
}

.adventureLocations .scheduledTripsItem:hover {
	background-color: var(--primaryBackgroundColorLight);
}

.inBetweenMenu {
	display: flex;
	justify-content: space-between;
}

/* .inBetweenMenu > div:first-of-type,
.inBetweenMenu > div:last-of-type {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--secondaryBackgroundColorLight);
	border-radius: 10px;
	font-size: small;
	cursor: pointer;
	width: 20px;
	height: 20px;
	padding: 5px;
} */

.inBetweenMenu > div:first-of-type {
	transform: rotate(90deg) scaleX(-1);
	/* background-color: yellow; */
}

.inBetweenMenu > div:last-of-type {
	transform: rotate(180deg) scaleX(-1);
	/* background-color: fuchsia; */
}

.inBetweenMenu > div:hover {
	background-color: var(--primaryBackgroundColorLight);
}

.inBetweenMenu > div > div:first-of-type {
	font-size: smaller;
	margin-right: 5px;
}

.scheduledTripsItemEmoji {
	font-size: 40px;
	flex-basis: 15%;
	color: var(--accentColorLight);
	text-align: center;
	justify-content: center;
}

.scheduledTripsItemText {
	position: relative;
	left: -5px;
	display: grid;
	grid-template-rows: auto auto; /* Make the rows the size of their content */
	grid-row-gap: 5px;
	align-items: center;
	flex-basis: 50%;
}

.scheduledTripsItemDates {
	font-size: small;
	color: var(--secondaryTxtColorLight);
}

.scheduledAdventuresArea .scheduledTripsItem:hover.clicked {
	cursor: pointer;
	/* font-weight: 500; */
	background-color: var(--primaryBackgroundColorLight);
	box-shadow: inset 0 0 20px var(--accentColorLight);
	transition: box-shadow 0.3s ease, font-weight 0.2s ease;
}

.happeningNowAdventures .scheduledTripsItem {
	display: flex;
}

.happeningNowAdventures .scheduledTripsItem > :first-child {
	text-align: center;
	flex: 0 0 15%;
}

.happeningNowAdventures .scheduledTripsItem > :nth-child(2) {
	left: 0;
	text-align: center;
	flex: 0 0 70%;
}

.happeningNowAdventures .scheduledTripsItem > :last-child {
	flex: 0 0 15%;
}

.adventureLocations:hover .clicked {
	cursor: pointer;
	font-weight: bold;
	box-shadow: inset 0 0 20px var(--accentColorLight);
	transition: box-shadow 0.3s ease, font-weight 0.2s ease;
}

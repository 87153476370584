.ItineraryItems::-webkit-scrollbar {
	display: none;
}

.ItineraryItems {
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
	overflow-y: hidden;
	overflow-x: auto;
	padding-bottom: 20px;
	width: calc(100% + 5px);
	position: relative;
	left: -3px;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}

.itineraryItem {
	background-color: var(--secondaryBackgroundColorLight);
	border-top: var(--accentColorLight) solid 0px;
	margin-top: 5px;
	cursor: pointer;
	width: 60px;
	padding: 17px;
	text-align: center;
	border: var(--primaryBackgroundColorLight) solid 0px;
	border-radius: 30px;
	transition: border-radius 0.3s ease 0.4s, border-color 0.3s ease 0.4s,
		padding-bottom 0.3s ease 0.4s, margin-bottom 0.3s ease 0.4s,
		border-width 0.3s ease 0.3s, margin-top 0.4s ease 0.3s,
		background-color 0.3s ease;
}

.itineraryItem:hover {
	background-color: var(--primaryBackgroundColorLight);
}

.itineraryItem.selected {
	background-color: var(--primaryBackgroundColorLight);
	margin-top: 0;
	padding-bottom: 50px;
	margin-bottom: -33px;
	border-radius: 15px 15px 0 0;
	/* z-index: 3000; */
	border-top: var(--accentColorLight) solid thick;
	/* border-left: var(--accentColorLight) solid thick; */
	transition: border-radius 0.3s ease, border-color 0.3s ease,
		border-width 0.3s ease, padding-bottom 0.3s ease, margin-bottom 0.3s ease,
		background-color 0.3s ease;
}

.itineraryDesc {
	font-size: x-large;
	font-weight: bold;
	margin-bottom: 5px;
}

.itinerarySeparator {
	padding-right: 5px;
	padding-left: 5px;
}

.itineraryDayAndMonth {
	font-size: medium;
	/* font-weight: bold; */
	margin-bottom: 5px;
	display: flex;
	align-items: center;
	text-align: center;
}

.ItineraryDayItem {
	width: 85%;
	background-color: var(--lowkeyBtnColorLight);
	padding-top: 10px;
	padding-bottom: 10px;
	padding-right: 7px;
	padding-left: 7px;
	border-radius: 10px;

	border-left: 3px solid var(--lowkeyBtnColorLight);
	border-right: 3px solid var(--lowkeyBtnColorLight);

	margin-bottom: 5px;
	text-align: center;
	cursor: pointer;
	display: grid;
	gap: 8px;
	transition: border-color 0s;
}

.ItineraryDayItem:hover {
	background-color: var(--lowkeyBtnColorHoverLight);
	border-color: var(--lowkeyBtnColorHoverLight);
	transition: border-color 0s;
}

.ItineraryDayItem.selectedActivity {
	border-right: 3px solid var(--accentColorLight);
	border-left: 3px solid var(--accentColorLight);
}

.ItineraryDayItem > div {
	display: flex;
	align-items: center; /* Vertical alignment */
	justify-content: center;
}

.ItineraryDayItemEmoji {
	grid-column: 1;
}

.ItineraryDayItemTime {
	grid-column: 2;
}

.ItineraryDayItemEditBtn {
	grid-column: 3;
}

.ItineraryDayDetailContent {
	display: flex;
	justify-content: space-between;
}

.ItineraryDayContent .leftColumn {
	width: 60%;
	min-height: 100%;
	/* max-height: 330px; */
	overflow-y: auto;
	overflow-x: hidden;
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
	display: flex;
	flex-direction: column;
	border-radius: 10px;
}

.ItineraryDayContent .leftColumn::-webkit-scrollbar {
	display: none; /* Safari and Chrome */
}

.ItineraryDayContent .rightColumn {
	width: 72%;
	background-color: var(--secondaryBackgroundColorLight);
	border-radius: 10px;
	margin-left: 10px;
	padding: 15px;
	max-height: 330px;
}

.itineraryRightColumnContent {
	display: flex;
	flex-direction: column;
}

.itineraryRightColumnContent > div:first-of-type {
	height: 200px;
}

.ItineraryDay {
	background-color: var(--primaryBackgroundColorLight);
	width: calc(100% + 5px);
	position: relative;
	top: -12px;
	left: -3px;
	border-radius: 15px 15px 15px 15px;
	border-bottom: 0px solid var(--primaryBackgroundColorLight);
	overflow: hidden;
	transition: max-height 0.5s ease-in-out, border-color 0.4s ease 0.2s,
		border-width 0.3s ease 0.2s;
	max-height: 0;
}

.ItineraryDay.selected {
	border-bottom: var(--accentColorLight) solid thick;
	transition: border-width 0.3s ease 0.1s, max-height 0.5s ease-in-out,
		border-color 0.4s ease 0s;
	/* transition: border-radius 0.3s ease, border-color 0.3s ease,
    border-width 0.3s ease, padding-bottom 0.3s ease, margin-bottom 0.3s ease,
    background-color 0.3s ease; */
	max-height: 900px;
}

.ItineraryDayContent {
	padding-top: 20px;
	padding-left: 15px;
	padding-right: 15px;
	padding-bottom: 20px;
}

.modifyDaySelect select {
	border-radius: 10px;
	padding: 5px;
	font-size: small;
	color: var(--primaryTxtColorLight);
	background-color: var(--secondaryBackgroundColorLight);
}

.modifyDaySelect select:focus {
	outline: none;
	box-shadow: 0px 0px 5px var(--accentColorLight);
	border-color: var(--accentColorLight);
}

.eventTitle {
	text-align: center;
	align-items: center;
	margin-bottom: 5px;
	padding-bottom: 5px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 170px;
}

.eventContent {
	display: grid;
	grid-template-columns: 1fr 5fr;
	grid-row-gap: 15px;
	grid-column-gap: 10px;
	font-size: small;
}

.eventContentDesc {
	font-weight: bold;
	margin-bottom: 5px;
}

.eventContentVal {
	margin-bottom: 15px;
	word-wrap: break-word;
	/* max-width: 170px; */
	word-wrap: break-word;
}

.scheduledItemAddress > a,
.lodgingInfoContent a {
	color: var(--primaryTxtColorLight);
	text-decoration: none;
	text-overflow: ellipsis;
}

.scheduledItemAddress > a:hover,
.lodgingInfoContent a:hover {
	text-decoration: underline;
}

.noItineraryItemsForDay {
	padding: 20px;
	text-align: center;
}

.eventContentVal {
	padding: 10px;
	border-radius: 10px;
	text-align: center;
	cursor: pointer;
}

.scheduledItemAddress {
	text-align: left;
	white-space: pre-line; /* This allows text wrapping across lines */
	line-height: 1.2em; /* Adjust based on your design */
	max-height: 4.6em; /* 1.2em (line-height) * 3 (lines) */
	overflow: hidden; /* Ensures any extra content is hidden */
}

.eventContentVal:hover,
.scheduledItemAddress:hover {
	background-color: var(--lowkeyBtnColorHoverLight);
	border-radius: 10px;
	padding: 10px;
}

.scheduledItemOptionArea {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	font-size: small;
}

.scheduledItemOption {
	width: 60px;
	height: 60px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	color: var(--secondaryTxtColorLight);
}

.scheduledItemOption:hover,
.deleteItemOption:hover {
	background-color: var(--lowkeyBtnColorHoverLight);
}

.newItineraryItemForm {
	text-align: center;
	/* margin-top: 35px;
	margin-bottom: 40px; */
}

.newItineraryItemForm > input {
	font-family: Roboto;
}

.clipboardCopyIconAddress {
	cursor: pointer;
	font-weight: normal;
	font-size: small;
	display: flex;
}

.ItineraryDayContentHeader {
	/* background-color: red; */
	display: flex;
	align-items: center;
}

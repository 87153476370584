.userAreaTitle {
	font-size: large;
	padding: 15px;
}

.userAreaContent {
	font-size: medium;
	text-align: left;
	margin-top: 20px;
	color: var(--primaryTxtColorLight);
}

hr {
	border-style: solid;
	border-width: 1px;
	border-color: var(--lowkeyBtnColorLight);
}

.userAreaContent.mainMenu hr {
	margin-bottom: 20px;
}

.gallivibeMainTitle {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	font-size: large;
	margin-top: 10px;
	/* background-color: violet; */
}

.avatarContainer,
.extraContentContainer {
	flex: 1; /* Both of these will take up an equivalent, smaller portion of the space */
	display: flex;
	cursor: pointer;
	align-items: center; /* Center their content vertically */
}

.avatarContainer {
	justify-content: left;
}

.extraContentContainer {
	justify-content: right;
}

.messageContainer {
	flex: 5; /* This will make the message container take up more space than either of the other two */
	text-align: center;
	height: 100%;
}

.tripAdventureTitle {
	display: inline-block;
	max-width: 85px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: pointer;
	padding: 10px;
	border-radius: 10px;
}

.tripAdventureTitle:hover {
	background-color: var(--primaryBackgroundColorLight);
}

.userAreaTitle > div:first-of-type {
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
}

.tripHeader {
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}

.tripHeaderLocation {
	padding: 10px;
}

.menuSubtitle {
	margin-top: 20px;
	margin-bottom: 20px;
}

.tripAnnouncement {
	text-align: center;
	margin-bottom: 22px;
	padding: 12px;
}

.TripArea {
	margin-bottom: 30px;
}

.Itinerary {
	background-color: var(--lowkeyBtnColorLight);
	border-color: var(--lowkeyBtnColorLight);
	position: relative;
	left: -16px;
	padding: 15px 16px 0px 16px;
	width: calc(100%);
	border-radius: 15px;
}

.tripAreaDesc {
	margin-bottom: 10px;
}

.TripAreaBasic {
	background-color: var(--lowkeyBtnColorLight);
	position: relative;
	left: -16px;
	padding: 15px 16px 15px 16px;
	width: calc(100%);
	border-radius: 15px;
}

.tripOthers {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	font-size: small;
}

.tripOthersItem {
	width: 100px;
	height: 100px;
	background-color: var(--lowkeyBtnColorLight);
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	cursor: pointer;
}

.tripOthersItem:hover {
	background-color: var(--lowkeyBtnColorHoverLight);
}

.tripOthersItem div:last-child {
	position: relative;
	top: 10px;
}

.TripItemArea {
	position: relative;
	width: calc(100% - 28px);
	background-color: var(--secondaryBackgroundColorLight);
	border-radius: 10px;
	padding: 15px;
	margin-bottom: 10px;
	display: grid;
	grid-template-columns: 1fr 5fr 1fr;
	align-items: center;
	cursor: pointer;
	align-items: center;
	z-index: 2;
	border: 0px solid var(--primaryBackgroundColorLight);
	transition: border 0.1s ease-in-out 0.5s;
}

.TripItemArea:hover {
	background-color: var(--primaryBackgroundColorLight);
}

.TripItemAreaSelected {
	background-color: var(--primaryBackgroundColorLight);
	border-bottom: thick solid var(--accentColorLight);
	transition: border 0.1s ease-in-out, background-color 0.3s ease;
}

.TripItemAreaTxt {
	text-align: right;
	grid-column: 2;
	text-align: center;
}

.TripItemAreaAction {
	text-align: right;
}

.TripItemAreaAction:hover {
	text-decoration: underline;
}

.deletingTripPage {
	text-align: center;
	line-height: 1.5;
	margin-bottom: 50px;
}

.deletingTripPage > h3 {
	padding-top: 30px;
	padding-right: 50px;
	padding-left: 50px;
}

hr {
	border-color: var(--lowkeyBtnColorHoverLight);
}

.deleteItemContent {
	text-align: center;
	margin-top: 25%;
	margin-bottom: 25%;
	padding: 12px;
}

.deleteItemOption {
	width: 70px;
	height: 70px;
	/* background-color: rgb(236, 235, 235); */
	background-color: var(--lowkeyBtnColorLight);
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	cursor: pointer;
	font-size: small;
}

.aiSuggestion {
	background-color: var(--lowkeyBtnColorLight);
	width: calc(98%);
	padding: 20px;
	border-radius: 20px;
	margin-bottom: 30px;
	position: relative;
	left: -16px;
}

.aiSuggestionTxt {
	background-color: var(--secondaryBackgroundColorLight);
	padding: 20px;
	/* border-radius: 20px; */
	width: calc(100%);
	position: relative;
	left: -20px;
	margin-top: 20px;
	border-top-style: solid;
	border-top-width: medium;
	border-top-color: var(--accentColorLight);
	border-bottom-style: solid;
	border-bottom-width: medium;
	border-bottom-color: var(--accentColorLight);
	min-height: 70px;
}

.tripSettings > div:first-of-type {
	padding: 10px;
	text-align: center;
}

.tripSetting {
	background-color: var(--lowkeyBtnColorLight);
	border-radius: 15px;
	padding: 15px;
	margin-bottom: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}

.AuthPageMessage {
	margin-bottom: 20px;
	font-size: medium;
	display: inline-block;
	min-height: 1em;
	width: 95%;
	border-radius: 10px;
	padding: 10px;
}

.authenticateUserFlowContent {
	background-color: var(--lowkeyBtnColorLight);
	padding: 15px;
	border-radius: 10px;
}

.verificationCode {
	width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;
	font-size: 16px;
	border: none;
	border-radius: 4px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 5px;
	/* background-color: lime; */
	/* align-items: center; */
}

.verificationCodeInput::-webkit-outer-spin-button,
.verificationCodeInput::-webkit-inner-spin-button {
	display: none;
}

.verificationCodeInput {
	display: block;
	flex: 1;
	background-color: var(--primaryBackgroundColorLight);
	padding: 13px;
	width: 0.7em;
	/* height: 0.7em; */
	max-width: 16vw; /* Adjust maximum width as necessary */
	height: 1.3em; /* This makes the height equal to the width */
	border-radius: 10px;
	border-style: solid;
	border-color: var(--primaryBackgroundColorLight);
	font-weight: bold;
	font-size: large;
	outline: none;
	text-align: center;
	color: var(--primaryTxtColorLight);
}

.verificationCodeInput:last-of-type {
	margin-right: 0; /* Removes margin from the last item */
}

.verificationCodeInput:focus {
	box-shadow: 0px 0px 5px var(--accentColorLight);
}

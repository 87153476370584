/* Styles for mobile devices */
@media (max-width: 767px) {
	.App-body {
		width: 93%;
		/* other mobile-specific styles */
	}
}

/* Styles for desktop */
@media (min-width: 768px) {
	.App-body {
		width: 400px; /* or whatever width you desire for desktop */
		/* other desktop-specific styles */
	}
}

/* .mainAppArea {
	width: 800px;
} */

.mainAppArea > div {
	width: 100%;
}

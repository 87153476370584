.addLocationBtn {
	background-color: var(--accentColorLight);
	color: var(--primaryBackgroundColorLight);
}

.addLocationBtn:hover {
	background-color: var(--HighlightBackgroundLight);
	color: var(--primaryBackgroundColorLight);
}

.addLocationBtnYassified {
	background-color: var(--lowkeyBtnColorHoverLight);
}

.adventureLocationScroll {
	/* margin-bottom: 10px; */
	/* background-color: lightcoral; */
	width: 100%; /* Set the container to 100% width */
	display: flex;
	overflow-x: scroll; /* Use overflow-x for horizontal scrolling */
	scroll-snap-type: x mandatory; /* Specify scroll-snap-type */
	scroll-behavior: smooth;
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
}

.adventureLocationScroll::-webkit-scrollbar {
	display: none; /* Safari and Chrome */
}

.adventureLocationScroll > div {
	/* Adjust the width to fit within the container */
	flex: 0 0 100%; /* Ensure child elements take up the entire width */
	scroll-snap-align: start; /* Snap child elements to the start */
	display: flex;
	flex-direction: column;
	margin-right: 20px;
}

/* Add scroll arrows */
.scroll-arrow {
	position: absolute;
	top: 46%;
	transform: translateY(-50%);
	width: 17px;
	cursor: pointer;
	z-index: 1; /* Ensure the arrows overlay the content */
	color: var(--lowkeyBtnColorLight);
	/* background-color: red; */
	display: flex;
	transition: color 140ms ease-in-out;
}

.selected {
	color: var(--primaryTxtColorLight);
}

.scroll-left {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	left: -4px;
	/* background-color: yellow; */
	text-align: left;
	align-items: flex-start;
	justify-content: flex-start;
}

.scroll-right {
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	right: 3px;
	justify-content: flex-start;
}

.itineraryComponent .scroll-arrow {
	transition: background-color 0.1s ease;
	top: 100px;
}

.itineraryComponent .scroll-left {
	left: -5px;
}

.itineraryComponent .scroll-left {
	right: 2px;
}

.locationCircleStatus {
	/* background-color: fuchsia; */
	margin: 2px;
	font-size: 5px;
	color: var(--primaryBackgroundColorLight);
}

/* .locationCircleStatus.selected {
	font-size: 5px;
	color: var(--primaryBackgroundColorLight);
} */

.viewCheckInHistory:hover {
	text-decoration: underline;
	cursor: pointer;
}

.checkInDateBlock {
	background-color: var(--primaryBackgroundColorLight);
	padding-top: 5px;
	padding-bottom: 15px;
	margin-bottom: 10px;
	border-radius: 20px;
}

.adventureCheckIn {
	margin-top: 10px;
	margin-bottom: 10px;

	padding: 10px;
	/* border-radius: 15px; */
	/* border-right: var(--primaryBackgroundColorLight) thi solid; */
	border-left: var(--accentColorLight) medium solid;
	border-right: var(--accentColorLight) medium solid;

	background-color: var(--secondaryBackgroundColorLight);

	display: flex;
	align-items: flex-start;
	gap: 10px;
	/* flex-grow: 1; */
}

.groupedCheckInDate {
	font-weight: bold;
	text-align: center;
	color: var(--accentColorLight);
	margin: 20px;
}

.checkInHeader {
	background-color: var(--primaryBackgroundColorLight);
	padding: 15px;
	border-radius: 20px;
	flex: 0 1 auto;
}

.checkInNote {
	/* background-color: lightpink; */
	/* text-overflow: ellipsis; */
	background-color: var(--primaryBackgroundColorLight);
	padding: 15px;
	border-radius: 10px;
	flex-grow: 1;
	width: 100px;
}

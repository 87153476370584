.ToSOrPrivacyPolicy {
  width: 400px;
}

.ToSOrPrivacyPolicy {
  padding: 10px;
  font-size: large;
}

.ToSOrPrivacyPolicy > p {
  text-justify: newspaper;
  font-size: medium;
  text-align: left;
}

.lastUpdate {
  font-size: small;
}
